import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c(VSimpleTable,{attrs:{"fixed-header":"","height":"400px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Nome")]),_c('th',{staticClass:"text-left"},[_vm._v("E-mail")]),_c('th',{staticClass:"text-left"},[_vm._v("Gestor")]),_c('th',{staticClass:"text-left"},[_vm._v("Ações")])])]),_c('tbody',_vm._l((_vm.dataSpreadsheets),function(item){return _c('tr',{key:item.id},[_c('td',[(item.id_setor == null || item.id_cargo == null)?_c(VTooltip,{attrs:{"color":"primary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBadge,{attrs:{"bordered":"","overlap":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',[_c(VIcon,[_vm._v("mdi-alert-circle-outline")])],1)]},proxy:true}],null,true)},[_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.nome)+" ")])])]}}],null,true)},[(item.id_setor == null && item.id_cargo != null)?_c('span',[_vm._v("Não tem setor definido!")]):_vm._e(),(item.id_setor != null && item.id_cargo == null)?_c('span',[_vm._v("Não tem cargo definido!")]):_vm._e(),(item.id_setor == null && item.id_cargo == null)?_c('span',[_vm._v("Não tem setor e cargo definidos!")]):_vm._e()]):_c('span',[_vm._v(_vm._s(item.nome))])],1),_c('td',[_vm._v(_vm._s(item.email))]),_c('td',[_c(VChip,{attrs:{"color":item.gestor == 1 ? 'accent' : 'secondary'}},[_vm._v(" "+_vm._s(item.gestor == 1 ? "Sim" : "Não")+" ")])],1),_c('td',[_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c(VList,[_c(VListItem,[_c(VListItemTitle,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.edit(item)}}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"actions"},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1)],1),_c(VListItem,[_c(VListItemTitle,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.remove(item, true)}}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"actions"},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Deletar")])])],1)],1)],1)],1)],1)])}),0)]},proxy:true}])}),_c('ModalDelete',{attrs:{"dialogDelete":_vm.dialogDelete}},[_c('template',{slot:"modalDelete/titleModal"},[_vm._v(" Excluir o registro? ")]),_c('template',{slot:"modalDelete/contentModal"},[_c('p',[_vm._v(" Atenção! Você está prestes a realizar uma ação que não pode ser desfeita. ")]),_c('p',[_vm._v("Você realmente deseja excluir esse registro?")])]),_c('template',{slot:"modalDelete/actionsModal"},[_c(VBtn,{attrs:{"depressed":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Fechar")]),_c(VBtn,{attrs:{"loading":_vm.loadingButtonDelete,"color":"error"},on:{"click":function($event){return _vm.remove()}}},[_vm._v(" Excluir ")]),_c(VSpacer,{staticClass:"d-none d-md-block"})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }