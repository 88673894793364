<template>
  <div class="d-flex flex-column">
    <FormColaboradores></FormColaboradores>

    <div class="d-flex flex-row align-center justify-end">
      <v-btn outlined color="secondary" @click="close()">Voltar</v-btn>
      <v-btn
        :loading="loadingButtonSave"
        class="ml-2"
        color="primary"
        @click="save()"
        >Salvar</v-btn
      >
    </div>
  </div>
</template>

<script>
import FormColaboradores from "./components/FormColaboradores.vue";

export default {
  name: "FormCollaboratorsComponent",
  inject: ["$validator"],
  components: {
    FormColaboradores,
  },
  data() {
    return {
      loadingButtonSave: false,
    };
  },
  computed: {
    colaborador: {
      get() {
        return this.$store.getters["avdColaboradores/getColaborador"];
      },
      set(value) {
        this.$store.dispatch("avdColaboradores/setColaborador", value);
      },
    },
    colaboradores: {
      get() {
        return this.$store.getters["avdColaboradores/getColaboradores"];
      },
    },
    fullDataAvaliacao: {
      get() {
        return this.$store.getters["avd/getFullDataAvaliacao"];
      },
    },
    dataSpreadsheets: {
      get() {
        return this.$store.getters["avdColaboradores/getDataSpreadsheets"];
      },
    },
  },
  methods: {
    /**
     * @description Atualiza um colaborador.
     */
    save() {
      this.$validator.validate("avd-colaborador.*").then((result) => {
        if (result) {
          this.loadingButtonSave = true;

          let colaboradorItem = this.colaborador;
          colaboradorItem._method = "PUT";
          colaboradorItem.url = `/avd-colaborador/${this.colaborador.uuid}`;
          colaboradorItem.id_cliente = this.fullDataAvaliacao.avaliacao
            .id_cliente
            ? this.fullDataAvaliacao.avaliacao.id_cliente
            : null;

          this.$store
            .dispatch("avdColaboradores/save", colaboradorItem)
            .then((response) => {
              if (response != "NAO_AUTORIZADO") {
                const index = this.dataSpreadsheets.findIndex(
                  (el) => el.id == colaboradorItem.id
                );

                Object.assign(this.dataSpreadsheets[index], colaboradorItem);

                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  mensagem: "Colaborador atualizado com sucesso.",
                  cor: "green",
                });
              }
            })
            .catch(() => {
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                mensagem: "Erro ao atualizar colaborador.",
                cor: "red",
              });
            })
            .finally(() => {
              this.close();
            });
        }
      });
    },
    close() {
      this.resetDatas();
      this.$emit("close-form");
    },
    resetDatas() {
      this.loadingButtonSave = false;
      this.colaborador = {
        nome: "",
        email: null,
        id_cliente: null,
        id_setor: null,
        id_cargo: null,
        id_gestor: null,
        data_admissao: null,
        gestor: false,
      };
    },
  },
};
</script>

<style></style>
