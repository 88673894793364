<template>
  <section>
    <v-simple-table fixed-header height="400px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Nome</th>
            <th class="text-left">E-mail</th>
            <th class="text-left">Gestor</th>
            <th class="text-left">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in dataSpreadsheets" :key="item.id">
            <td>
              <v-tooltip
                v-if="item.id_setor == null || item.id_cargo == null"
                color="primary"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-badge bordered overlap>
                    <template v-slot:badge>
                      <span>
                        <v-icon>mdi-alert-circle-outline</v-icon>
                      </span>
                    </template>

                    <span v-bind="attrs" v-on="on">
                      {{ item.nome }}
                    </span>
                  </v-badge>
                </template>
                <span v-if="item.id_setor == null && item.id_cargo != null"
                  >Não tem setor definido!</span
                >
                <span v-if="item.id_setor != null && item.id_cargo == null"
                  >Não tem cargo definido!</span
                >
                <span v-if="item.id_setor == null && item.id_cargo == null"
                  >Não tem setor e cargo definidos!</span
                >
              </v-tooltip>
              <span v-else>{{ item.nome }}</span>
            </td>
            <td>{{ item.email }}</td>
            <td>
              <v-chip :color="item.gestor == 1 ? 'accent' : 'secondary'">
                {{ item.gestor == 1 ? "Sim" : "Não" }}
              </v-chip>
            </td>
            <td>
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <!-- Editar -->
                  <v-list-item>
                    <v-list-item-title>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="info"
                            v-bind="attrs"
                            v-on="on"
                            @click="edit(item)"
                          >
                            <v-icon class="actions"> mdi-pencil </v-icon>
                          </v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>
                    </v-list-item-title>
                  </v-list-item>

                  <!-- Delete -->
                  <v-list-item>
                    <v-list-item-title>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="error"
                            v-bind="attrs"
                            v-on="on"
                            @click="remove(item, true)"
                          >
                            <v-icon class="actions"> mdi-delete </v-icon>
                          </v-btn>
                        </template>
                        <span>Deletar</span>
                      </v-tooltip>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <ModalDelete :dialogDelete="dialogDelete">
      <template slot="modalDelete/titleModal"> Excluir o registro? </template>
      <template slot="modalDelete/contentModal">
        <p>
          Atenção! Você está prestes a realizar uma ação que não pode ser
          desfeita.
        </p>
        <p>Você realmente deseja excluir esse registro?</p>
      </template>
      <template slot="modalDelete/actionsModal">
        <v-btn depressed @click="dialogDelete = false">Fechar</v-btn>
        <v-btn :loading="loadingButtonDelete" color="error" @click="remove()">
          Excluir
        </v-btn>
        <v-spacer class="d-none d-md-block"></v-spacer>
      </template>
    </ModalDelete>
  </section>
</template>

<script>
import ModalDelete from "@/components/ModalDelete";

export default {
  name: "TableImportCollaborators",
  components: {
    ModalDelete,
  },
  data() {
    return {
      // Modal delete
      dialogDelete: false,
      loadingButtonDelete: false,
      item: [],
    };
  },
  computed: {
    dataSpreadsheets: {
      get() {
        return this.$store.getters["avdColaboradores/getDataSpreadsheets"];
      },
    },
  },
  methods: {
    edit(item) {
      this.$store.dispatch("avdColaboradores/setColaborador", item);
      this.$emit("edit");
    },
    remove(item, openModal = false) {
      if (openModal) {
        this.item = Object.assign({}, item);
        this.dialogDelete = true;
      } else {
        this.loadingButtonDelete = true;
        const indexItem = this.dataSpreadsheets.findIndex(
          (el) => el.id == this.item.id
        );

        this.$store
          .dispatch("avdColaboradores/remove", this.item.uuid)
          .then((response) => {
            if (response === true) {
              this.dataSpreadsheets.splice(indexItem, 1);

              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: "Colaborador removido com sucesso.",
              });
            }
          })
          .catch(() => {
            this.$store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Não foi possível remover o colaborador. Por favor, tente novamente mais tarde!",
            });
          })
          .finally(() => {
            this.loadingButtonDelete = false;
            this.dialogDelete = false;
            this.item = [];
          });
      }
    },
  },
};
</script>

<style></style>
