<template>
  <v-container fluid>
    <v-row class="ma-3" wrap>
      <!-- nome -->
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-text-field
          label="Nome do colaborador"
          ref="nome"
          placeholder="Ex.: Administrativo"
          required
          outlined
          dense
          v-model="colaborador.nome"
          data-vv-as="nome"
          persistent-hint
          :hint="errors.first('avd-colaborador.nome')"
          :error="errors.collect('avd-colaborador.nome').length ? true : false"
          v-validate="'required'"
          data-vv-validate-on="change"
          data-vv-scope="avd-colaborador"
          id="nome"
          name="nome"
        ></v-text-field>
      </v-col>
      <!-- email -->
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-text-field
          label="E-mail"
          ref="email"
          placeholder="Ex.: exemplo@email.com"
          required
          outlined
          dense
          v-model="colaborador.email"
          data-vv-as="e-mail"
          persistent-hint
          :hint="errors.first('avd-colaborador.email')"
          :error="errors.collect('avd-colaborador.email').length ? true : false"
          v-validate="'required'"
          data-vv-validate-on="change"
          data-vv-scope="avd-colaborador"
          id="email"
          name="email"
        ></v-text-field>
      </v-col>
      <!-- AvdSetor -->
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-select
          label="Setor"
          :items="setores"
          item-text="nome"
          item-value="id"
          placeholder="Selecione um setor"
          outlined
          dense
          required
          v-model="colaborador.id_setor"
          :loading="loadings.loadingAvdSetor"
          data-vv-as="setor"
          persistent-hint
          :hint="errors.first('avd-colaborador.setor')"
          :error="errors.collect('avd-colaborador.setor').length ? true : false"
          v-validate="'required'"
          data-vv-validate-on="change|blur|input"
          data-vv-scope="avd-colaborador"
          id="setor"
          name="setor"
          no-data-text="Nenhum setor selecionado"
        ></v-select>
      </v-col>
      <!-- AvdCargos -->
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-select
          label="Cargo"
          :items="cargos"
          item-text="nome"
          item-value="id"
          placeholder="Selecione um cargo"
          outlined
          dense
          required
          v-model="colaborador.id_cargo"
          :loading="loadings.loadingAvdCargo"
          data-vv-as="cargo"
          persistent-hint
          :hint="errors.first('avd-colaborador.cargo')"
          :error="errors.collect('avd-colaborador.cargo').length ? true : false"
          v-validate="'required'"
          data-vv-validate-on="change|blur|input"
          data-vv-scope="avd-colaborador"
          id="cargo"
          name="cargo"
          no-data-text="Nenhum cargo selecionado"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-menu
          v-model="menu.data_admissao"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              persistent-hint
              v-model="dataAdmissao"
              id="data_admissao"
              name="data_admissao"
              label="Data de admissão"
              append-icon="mdi-calendar"
              readonly
              dense
              outlined
              hide-details
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="colaborador.data_admissao"
            @input="menu.data_admissao = false"
            locale="pt-BR"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <!-- Gestor -->
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-select
          :disabled="gestores.length == 1"
          :messages="
            gestores.length == 1 ? 'Não há nenhum gestor cadastrado' : ''
          "
          label="Gestor"
          :items="gestores"
          item-text="nome"
          item-value="id"
          placeholder="Selecione um gestor"
          outlined
          dense
          required
          v-model="colaborador.id_gestor"
          :loading="loadings.loadingColaborador"
          no-data-text="Nenhum gestor selecionado"
          :hide-details="gestores.length == 1 ? false : true"
        ></v-select>
      </v-col>
      <!-- É Gestor -->
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-switch
          v-model="colaborador.gestor"
          hide-details
          label="Este colaborador é um gestor?"
        ></v-switch>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  name: "FormColaboradores",
  inject: ["$validator"],
  async created() {
    const dataAvdSetor = {
      columns: ["id", "nome"],
      validate: [["status", "A"]],
    };
    const dataAvdCargo = {
      columns: ["id", "nome"],
      validate: [["status", "A"]],
    };

    await this.$store.dispatch("avdSetores/findByColumns", dataAvdSetor);
    this.loadings.loadingAvdSetor = false;

    await this.$store.dispatch("avdCargos/findByColumns", dataAvdCargo);
    this.loadings.loadingAvdCargo = false;
  },
  data() {
    return {
      loadings: {
        loadingAvdSetor: true,
        loadingAvdCargo: true,
      },
      menu: {
        data_admissao: false,
      },
    };
  },
  // TODO Falta puxar AvdSetor e AvdCargo
  computed: {
    colaborador: {
      get() {
        return this.$store.getters["avdColaboradores/getColaborador"];
      },
      set(value) {
        this.$store.dispatch("avdColaboradores/setColaborador", value);
      },
    },
    gestores: {
      get() {
        let colaboradores =
          this.$store.getters["avdColaboradores/getColaboradores"];

        colaboradores = colaboradores.filter((el) => el.gestor == 1);

        colaboradores.unshift({ id: null, nome: "Nenhum gestor selecionado" });

        return colaboradores;
      },
    },
    cargos: {
      get() {
        return this.$store.getters["avdCargos/getCargos"];
      },
    },
    setores: {
      get() {
        return this.$store.getters["avdSetores/getSetores"];
      },
    },
    dataAdmissao() {
      let date = moment(this.colaborador.data_admissao);

      if (date.isValid()) {
        return date.format("DD/MM/YYYY");
      }

      return null;
    },
  },
};
</script>

<style></style>
