<template>
  <v-dialog v-model="dialog" persistent max-width="85%">
    <v-card class="pa-2">
      <v-card-title tag="div">
        <v-file-input
          accept=".xlsx, .xls, .csv"
          label="Lista de colaboradores"
          placeholder="Insira uma planilha com a lista de seus colaboradores."
          show-size
          outlined
          hide-details
          prepend-icon="mdi-google-spreadsheet"
          v-model="planilha_colaboradores"
          data-vv-as="lista"
          persistent-hint
          :hint="errors.first('avd-modal-import-collaborators.lista')"
          :error="
            errors.collect('avd-modal-import-collaborators.lista').length
              ? true
              : false
          "
          v-validate="'required'"
          data-vv-validate-on="change|blur|input"
          data-vv-scope="avd-modal-import-collaborators"
          id="lista"
          name="lista"
        ></v-file-input>
        <v-btn
          @click="sendSpreadsheet()"
          color="primary"
          class="ml-2"
          :loading="loading"
          >Importar</v-btn
        >
        <v-spacer />
        <v-tooltip color="primary" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="loading"
              icon
              @click="close()"
              v-bind="attrs"
              v-on="on"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </template>
          <span>Fechar</span>
        </v-tooltip>
      </v-card-title>
      <v-divider
        v-if="showTableImportCollaboratos || showFormEdit"
        class="mb-2"
      ></v-divider>
      <v-card-text class="pa-0">
        <TableImportCollaborators
          v-if="showTableImportCollaboratos"
          @edit="edit()"
        ></TableImportCollaborators>
        <FormCollaboratorsComponent
          v-if="showFormEdit"
          @close-form="closeForm()"
        ></FormCollaboratorsComponent>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import TableImportCollaborators from "./components/TableImportCollaborators.vue";
import FormCollaboratorsComponent from "./components/FormCollaboratorsComponent.vue";

export default {
  name: "ModalImportCollaborators",
  inject: ["$validator"],
  components: {
    TableImportCollaborators,
    FormCollaboratorsComponent,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
      require: true,
    },
  },
  data() {
    return {
      loading: false,
      planilha_colaboradores: null,

      showTableImportCollaboratos: false,
      showFormEdit: false,
    };
  },
  computed: {
    dataSpreadsheets: {
      get() {
        return this.$store.getters["avdColaboradores/getDataSpreadsheets"];
      },
      set(value) {
        this.$store.dispatch("avdColaboradores/setDataSpreadsheets", value);
      },
    },
    colaboradores: {
      get() {
        return this.$store.getters["avdColaboradores/getColaboradores"];
      },
    },
  },
  methods: {
    close() {
      this.colaboradores.push(...this.dataSpreadsheets);
      this.dataSpreadsheets = [];

      this.$emit("close");
    },
    /**
     * @description Envia a planilha dos colaboradores para o servidor.
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    sendSpreadsheet() {
      this.$validator
        .validate("avd-modal-import-collaborators.*")
        .then((result) => {
          if (result) {
            this.loading = true;

            let form = new FormData();
            form.append("planilha_colaboradores", this.planilha_colaboradores);

            this.$store
              .dispatch("avdColaboradores/importCollaborators", form)
              .then((response) => {
                if (response != "NAO_AUTORIZADO") {
                  this.showTableImportCollaboratos = true;

                  this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                    visibilidade: true,
                    mensagem: "Colaboradores cadastrados com sucesso.",
                    cor: "green",
                  });
                }
              })
              .catch(() => {
                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  mensagem:
                    "Houve um erro ao cadastrar os colaboradores. Por favor, mais tarde tente novamente.",
                  cor: "red",
                });
              })
              .finally(() => {
                this.loading = false;
              });
          }
        });
    },
    edit() {
      this.showTableImportCollaboratos = false;
      this.showFormEdit = true;
    },
    closeForm() {
      this.showTableImportCollaboratos = true;
      this.showFormEdit = false;
    },
  },
};
</script>

<style></style>
