<template>
  <section name="cargo">
    <v-row>
      <v-col cols="12">
        <Crud
          title="Gestão de Colaboradores"
          buttonAddItemText="Novo colaborador"
          searchLabelText="Pesquisar na lista"
          :searchTextField.sync="searchTextField"
          :formTitle="nameForm"
          :callBackModalCancelar="fechar"
          :dialog.sync="dialog"
          :callBackModalSalvar="salvar"
          :loadingButtonSave="loadings.loadingButtonSave"
          :dialogDelete.sync="dialogDelete"
        >
          <template slot="data-content">
            <v-row class="mt-5">
              <!-- FILTRO CAMPOS -->
            </v-row>
          </template>
          <template slot="addButtonsArea">
            <v-btn
              @click="dialogModalImport = true"
              color="secondary"
              outlined
              class="mb-4 ml-3"
              large
            >
              <v-icon size="17" class="me-1"> mdi-import </v-icon>
              <span>Importar Colaboradores</span>
            </v-btn>
          </template>
          <template slot="data-table">
            <v-data-table
              :headers="
                headers.filter((header) => {
                  return header.value != 'editar' && header.value != 'acao';
                })
              "
              :items="colaboradores"
              :loading="loadings.loadingTable"
            >
              <!-- ACTIONS -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <!-- Visualizar -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon
                                class="actions"
                                @click="toggleModalLateral(item)"
                              >
                                mdi-eye
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Visualizar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>

                    <!-- Editar -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon class="actions" @click="editar(item)">
                                mdi-pencil
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>

                    <!-- Delete -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon
                                class="actions"
                                @click="remover(item, true)"
                              >
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Deletar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-switch
                  class="mt-0 pt-0"
                  v-model="item.status"
                  @click="mudarStatus(item)"
                  :label="item.status == 'A' ? 'Ativo' : 'Inativo'"
                  color="success"
                  true-value="A"
                  false-value="I"
                  hide-details
                ></v-switch>
              </template>
              <template v-slot:[`item.gestor`]="{ item }">
                <v-chip :color="item.gestor == 1 ? 'accent' : 'secondary'">{{
                  item.gestor == 1 ? "Sim" : "Não"
                }}</v-chip>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                <span
                  >{{ item.created_at | dateFormatBr() }}
                  {{ item.created_at | timeFormatBr() }}h</span
                >
              </template>
              <template v-slot:[`item.updated_at`]="{ item }">
                <span
                  >{{ item.updated_at | dateFormatBr() }}
                  {{ item.updated_at | timeFormatBr() }}h</span
                >
              </template>
            </v-data-table>
          </template>
          <!--------------- MODAL DE DELETE --------------------------------------------- -->
          <template slot="modalDelete/titleModal">
            Excluir o registro?
          </template>
          <template slot="modalDelete/contentModal">
            <p>
              Atenção! Você está prestes a realizar uma ação que não pode ser
              desfeita.
            </p>
            <p>Você realmente deseja excluir esse registro?</p>
          </template>
          <template slot="modalDelete/actionsModal">
            <v-btn depressed @click="dialogDelete = false">Fechar</v-btn>
            <v-btn
              :loading="loadingButtonDelete"
              color="error"
              @click="remover()"
            >
              Excluir
            </v-btn>
            <v-spacer class="d-none d-md-block"></v-spacer>
          </template>
          <!-- ------------- MODAL DE CADASTRO --------------------------------------------- -->
          <template slot="contentModalCrud">
            <FormColaboradores />
          </template>
        </Crud>
      </v-col>
    </v-row>
    <ModalLateral
      :show="modalLateral.show"
      :hide="toggleModalLateral"
      :title="modalLateral.title"
      :headers="headerModalLateral"
      :item="modalLateral.item"
    >
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="item.status == 'A' ? 'success' : 'secondary'">
          <span>{{ item.status == "A" ? "Ativo" : "Inativo" }}</span>
        </v-chip>
      </template>
      <template v-slot:[`item.gestor`]="{ item }">
        <v-chip :color="item.gestor == 1 ? 'accent' : 'secondary'">{{
          item.gestor == 1 ? "Sim" : "Não"
        }}</v-chip>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <span
          >{{ item.created_at | dateFormatBr() }}
          {{ item.created_at | timeFormatBr() }}h</span
        >
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        <span
          >{{ item.updated_at | dateFormatBr() }}
          {{ item.updated_at | timeFormatBr() }}h</span
        >
      </template>
      <template v-slot:[`item.data_admissao`]="{ item }">
        <span>{{ item.data_admissao | dateFormatBr() }} </span>
      </template>
    </ModalLateral>
    <ModalImportCollaborators
      @close="dialogModalImport = false"
      :dialog="dialogModalImport"
    ></ModalImportCollaborators>
  </section>
</template>

<script>
import Crud from "@/components/Crud.vue";
import ModalLateral from "@/components/ModalLateral.vue";
import FormColaboradores from "./components/colaboradores/components/components/FormColaboradores.vue";
import ModalImportCollaborators from "./components/colaboradores/ModalImportCollaborators.vue";

export default {
  name: "colaborador-Component",
  inject: ["$validator"],
  components: {
    Crud,
    ModalLateral,
    FormColaboradores,
    ModalImportCollaborators,
  },
  data() {
    return {
      nameForm: "Novo colaborador",
      modalLateral: {
        show: false,
        item: [],
        title: "",
      },
      SelectItensStatus: [
        { text: "Ativo", value: "A" },
        { text: "Inativo", value: "I" },
      ],
      searchTextField: "",
      dialog: false,
      loadings: {
        loadingButtonSave: false,
        loadingTable: false,
      },
      headers: [
        { text: "Nome", align: "start", value: "nome" },
        { text: "E-mail", value: "email" },
        { text: "Gestor", value: "gestor" },
        { text: "Status", value: "status" },
        { text: "Data de criação", value: "created_at" },
        { text: "Data de atualização", value: "updated_at" },
        { text: "Ações", align: "end", value: "actions", sortable: false },
      ],
      headerModalLateral: [
        { text: "Nome", value: "nome" },
        { text: "Email", value: "email" },
        { text: "Data de admissão", value: "data_admissao" },
        { text: "Gestor", value: "gestor" },
        { text: "Seu Gestor", value: "gestor_nome" },
        { text: "Setor", value: "setor_nome.nome" },
        { text: "Cargo", value: "cargo_nome.nome" },
        { text: "Data de criação", value: "created_at" },
        { text: "Data de atualização", value: "updated_at" },
        { text: "Status", value: "status" },
      ],

      // Modal delete
      dialogDelete: false,
      loadingButtonDelete: false,
      item: [],

      // ModalImportCollaborators
      dialogModalImport: false,
    };
  },

  async created() {
    this.loadings.loadingTable = true;
    const dataColaborador = {
      columns: ["id", "uuid", "nome", "email", "gestor", "status"],
      validate: this.fullDataAvaliacao.avaliacao.id_cliente
        ? [["id_cliente", this.fullDataAvaliacao.avaliacao.id_cliente]]
        : null,
    };

    await this.$store.dispatch(
      "avdColaboradores/findByColumns",
      dataColaborador
    );

    this.loadings.loadingTable = false;
  },

  computed: {
    colaborador: {
      get() {
        return this.$store.getters["avdColaboradores/getColaborador"];
      },
      set(value) {
        this.$store.dispatch("avdColaboradores/setColaborador", value);
      },
    },
    colaboradores: {
      get() {
        return this.$store.getters["avdColaboradores/getColaboradores"];
      },
    },
    fullDataAvaliacao: {
      get() {
        return this.$store.getters["avd/getFullDataAvaliacao"];
      },
    },
    computedHeaders() {
      return this.headers.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
  },

  methods: {
    salvar() {
      this.$validator.validate("avd-colaborador.*").then((result) => {
        if (result) {
          const isEdit = this.colaborador.id != undefined ? 1 : -1;
          this.loadings.loadingButtonSave = true;
          this.loadings.loadingTable = true;
          let url =
            isEdit === -1
              ? "/avd-colaborador"
              : `/avd-colaborador/${this.colaborador.uuid}`;
          let method = isEdit === -1 ? "POST" : "PUT";
          let sucesso =
            isEdit === -1
              ? "Colaborador cadastrado com sucesso."
              : "Colaborador atualizado com sucesso.";
          let erro =
            isEdit === -1
              ? "Erro ao salvar colaborador."
              : "Erro ao atualizar colaborador.";
          let colaboradorItem = this.colaborador;
          colaboradorItem._method = method;
          colaboradorItem.url = url;
          colaboradorItem.id_cliente = this.fullDataAvaliacao.avaliacao
            .id_cliente
            ? this.fullDataAvaliacao.avaliacao.id_cliente
            : null;

          this.$store
            .dispatch("avdColaboradores/save", colaboradorItem)
            .then((response) => {
              if (response != "NAO_AUTORIZADO") {
                if (isEdit === -1) {
                  this.colaboradores.push(response);
                } else {
                  const index = this.colaboradores.findIndex(
                    (el) => el.id == colaboradorItem.id
                  );

                  Object.assign(this.colaboradores[index], colaboradorItem);
                }

                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  mensagem: sucesso,
                  cor: "green",
                });
              }
            })
            .catch(() => {
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                mensagem: erro,
                cor: "red",
              });
            })
            .finally(() => {
              this.loadings.loadingButtonSave = false;
              this.loadings.loadingTable = false;
              this.fechar();
            });
        }
      });
    },
    editar(item) {
      this.loadings.loadingTable = true;

      this.$store
        .dispatch("avdColaboradores/findByUuid", item.uuid)
        .then((response) => {
          this.colaborador = response;
          this.nameForm = "Editar colaborador: " + this.colaborador.nome;
          this.dialog = true;
        })
        .catch(() => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem:
              "Houve um erro ao buscar todos os dados do colaborador selecionado.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadings.loadingTable = false;
        });
    },
    fechar() {
      this.dialog = false;
      this.nameForm = "Novo colaborador";
      // TODO mudar o dado aqui
      this.colaborador = {
        nome: "",
        email: null,
        id_cliente: null,
        id_setor: null,
        id_cargo: null,
        id_gestor: null,
        data_admissao: null,
        gestor: false,
      };
    },
    remover(item, openModal = false) {
      if (openModal) {
        this.item = Object.assign({}, item);
        this.dialogDelete = true;
      } else {
        this.loadingButtonDelete = true;
        const indexItem = this.colaboradores.findIndex(
          (el) => el.id == this.item.id
        );

        this.$store
          .dispatch("avdColaboradores/remove", this.item.uuid)
          .then((response) => {
            if (response === true) {
              this.colaboradores.splice(indexItem, 1);

              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: "Colaborador removido com sucesso.",
              });
            }
          })
          .catch(() => {
            this.$store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Não foi possível remover o colaborador. Por favor, tente novamente mais tarde!",
            });
          })
          .finally(() => {
            this.loadingButtonDelete = false;
            this.dialogDelete = false;
            this.item = [];
          });
      }
    },
    mudarStatus(item) {
      this.loadings.loadingTable = true;

      let colaboradorItem = {
        id: item.id,
        uuid: item.uuid,
        status: item.status,
        url: `/avd-colaborador/${item.uuid}`,
        _method: "PUT",
      };

      this.$store
        .dispatch("avdColaboradores/save", colaboradorItem)
        .then((response) => {
          if (response != "NAO_AUTORIZADO") {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem: "Status atualizado com sucesso.",
              cor: "green",
            });
          }
        })
        .catch(() => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Erro ao atualizar o status.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadings.loadingTable = false;
        });
    },
    toggleModalLateral(item) {
      if (!this.modalLateral.show) {
        this.loadings.loadingTable = true;

        this.$store
          .dispatch("avdColaboradores/findByUuid", item.uuid)
          .then((response) => {
            this.modalLateral.title = response.nome;
            this.modalLateral.item = item ? response : [];
            this.modalLateral.show = true;
          })
          .catch(() => {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem:
                "Houve um erro ao buscar todos os dados do colaborador selecionado.",
              cor: "red",
            });
          })
          .finally(() => {
            this.loadings.loadingTable = false;
          });
      } else {
        this.modalLateral.title = "";
        this.modalLateral.item = [];
        this.modalLateral.show = false;
      }
    },
  },
};
</script>

<style scoped></style>
